import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { FirstReportData, Plan, PlanSuggestion, ProblemWalletData, Report } from "../models/fraud.models";

@Injectable({
  providedIn: 'root'
})
export class MockFraudService {

  calculateRentalPlanForWallet(address: string): Observable<PlanSuggestion> {
    const plan: Plan = {
      id: '1234',
      minEnergy: 100,
      name: 'Basic Plan',
      priceUsdt: 99,
      txCount: 500,
      incomingCount: 0,
      outcomingCount: 0
    };

    const planSuggestion: PlanSuggestion = {
      amountOfPayedFee: 0,
      amountOfPayedFeeUsdt: 0,
      customerValue: 'AVERAGE',
      inCount: 0,
      outCount: 0,
      planResult: {
        economy: 0,
        planIntCount: 0,
        planMarketFee: 0,
        planMarketFeeUsdt: 0,
        planOutCount: 0,
        planPrice: 0,
        minInCount: 0,
        minOutCount: 0
      },
      transfersSpread: {},
      transfersSpreadDays: {},
      usesRentals: false
    };

    return of(planSuggestion);
  }

  getLatestReportById(id: number): Observable<Report<ProblemWalletData | FirstReportData>> {
    const data: Report<ProblemWalletData | FirstReportData> = {
      id: 13,
      isSmartContract: false,
      address: "TXV1hirz6GEHbJ9Fj9Gm172eZUeJzUvAjg",
      userUuid: "USER-220e09c6-3340-47cd-a5b0-63bcfec3934c",
      status: "COMPLETE",
      process: "FIRST_REPORT",
      type: "TRIAL",
      createdDate: "2024-05-22T13:08:22.988167Z",
      network: 'TRX',
      data: {
        planSuggestion: {
          amountOfPayedFee: 0,
          amountOfPayedFeeUsdt: 0,
          customerValue: "AVERAGE",
          inCount: 0,
          outCount: 0,
          planResult: {
            economy: 0,
            planIntCount: 0,
            planMarketFee: 0,
            planMarketFeeUsdt: 0,
            planOutCount: 0,
            planPrice: 0,
            minInCount: 0,
            minOutCount: 0
          },
          transfersSpread: {
            "MONDAY": 0,
            "TUESDAY": 0,
            "WEDNESDAY ": 0,
            "THURSDAY": 0,
            "FRIDAY": 0,
            "SATURDAY": 0,
            "SUNDAY": 0
          },
          transfersSpreadDays: {
            "2024-06-15": 0,
            "2024-06-14": 0,
            "2024-06-13": 0,
            "2024-06-12": 0,
            "2024-06-11": 0,
            "2024-06-10": 0,
            "2024-06-09": 0,
            "2024-06-08": 0,
            "2024-06-17": 0,
            "2024-06-16": 0
          },
          usesRentals: false
        },
        registeredAddressRiskResponse: {
          address: "TWBAPzpPiZarfVsY2BLXeaLhNHurn4wkWG",
          risk: "SEVERE",
          riskReason: "Identified as Sanctioned Entity",
          cluster: {
            name: "OFAC SDN Tawfiq Muhammad Said Al-Law 2024-03-26",
            category: "sanctioned entity"
          },
          addressIdentifications: [{
            "name": "SANCTIONED ENTITY: OFAC SDN Tawfiq Muhammad Sa’id Al-Law 2024-03-26 TWBAPzpPiZarfVsY2BLXeaLhNHurn4wkWG",
            "category": "sanctioned entity",
            "description": "This specific address TWBAPzpPiZarfVsY2BLXeaLhNHurn4wkWG has been identified as OFAC SDN Tawfiq Muhammad Sa’id Al-Law 2024-03-26. \n\nAL-LAW, Tawfiq Muhammad Sa'id (a.k.a. AL-LAW, Tawfiq; a.k.a. ALLOU, Tawfik), Beirut, Lebanon; DOB 04 May 1992; nationality Syria; Gender Male; Secondary sanctions risk: section 1(b) of Executive Order 13224, as amended by Executive Order 13886; Digital Currency Address - USDT TWBAPzpPiZarfVsY2BLXeaLhNHurn4wkWG; Passport N013053807 (Syria) (individual) [SDGT] (Linked To: HIZBALLAH).\n\nhttps://ofac.treasury.gov/recent-actions/20240326 "
          }, {
            "name": "TERRORIST FINANCING: NBCTF Seizures List - Tawfiq Muhammad Said Al-law 2023-06-28 TWBAPzpPiZarfVsY2BLXeaLhNHurn4wkWG",
            "category": "terrorist financing",
            "description": "This specific address TWBAPzpPiZarfVsY2BLXeaLhNHurn4wkWG has been identified as NBCTF Seizures List - Tawfiq Muhammad Said Al-law 2023-06-28. \n\nhttps://nbctf.mod.gov.il/he/PropertyPerceptions/Documents/%d7%a6%d7%95%20%d7%a7%d7%a8%d7%99%d7%a4%d7%98%d7%95.pdf"
          }],
          exposures: [{ "category": "atm", "value": 21.29 }, {
            "category": "bridge",
            "value": 49821.97
          }, { "category": "darknet market", "value": 8.79 }, {
            "category": "decentralized exchange",
            "value": 13871.42
          }, { "category": "exchange", "value": 1.356872816714E7 }, {
            "category": "exchange",
            "value": 3.027743418714E7
          }, { "category": "fee", "value": 206.74037 }, {
            "category": "fee",
            "value": 256.18037
          }, { "category": "fraud shop", "value": 2.29 }, {
            "category": "gambling",
            "value": 23586.2
          }, { "category": "hosted wallet", "value": 2905677.47 }, {
            "category": "illicit actor-org",
            "value": 114.58
          }, { "category": "lending", "value": 3549.57 }, {
            "category": "merchant services",
            "value": 11629.73
          }, { "category": "mining pool", "value": 3.73 }, {
            "category": "no kyc exchange",
            "value": 250149.99997
          }, { "category": "no kyc exchange", "value": 292540.21997 }, {
            "category": "other",
            "value": 534039.23
          }, { "category": "p2p exchange", "value": 4822.36 }, {
            "category": "protocol privacy",
            "value": 2490.77
          }, { "category": "sanctioned entity", "value": 396086.07 }, {
            "category": "sanctioned jurisdiction",
            "value": 13562.11
          }, { "category": "scam", "value": 4740.23 }, {
            "category": "seized funds",
            "value": 6.85
          }, { "category": "smart contract", "value": 1464.03 }, {
            "category": "stolen funds",
            "value": 16115.95
          }, { "category": "terrorist financing", "value": 9999.99998 }, {
            "category": "terrorist financing",
            "value": 15972.69998
          }, { "category": "token smart contract", "value": 8.72 }, {
            "category": "unnamed service",
            "value": 67531.58
          }, { "category": "unspent", "value": 157482.32 }],
          triggers: [{
            "category": "terrorist financing",
            "percentage": 0.028742,
            "message": "> 0% direct exposure to terrorist financing",
            "ruleTriggered": { "minThreshold": 0.0, "maxThreshold": 1.0, "risk": "SEVERE" }
          }], "status": "COMPLETE"
        }
      },
      approvedBy: '',
      approvedDate: '',
      sentDate: '',
      viewed: false
    }
    return of(data);
  }
}
