import { inject, Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, filter, map, Observable, of } from "rxjs";
import {
  AddWalletRequest,
  CreateReferralLinkRequest,
  InvoiceDto,
  LatestAmlReport,
  Operation,
  PaginationResponse,
  PlanCost,
  PlanSuggestion,
  Referral,
  RegisterUserForm,
  Rental,
  RentResourcesRequest,
  Report,
  ReportData,
  TransactionDto,
  TransactionEntity,
  UserEntity,
  Wallet,
  WalletCheckNetworkDto,
  WalletCheckQueueDto,
  WalletEntity,
  WalletWithRentalDto
} from "../models/fraud.models";
import { MockFraudService } from "./mock-fraud.service";

@Injectable({
  providedIn: 'root'
})
export class FraudService {
  private _apiUrl: string = environment.apiUrl;
  private _httpClient: HttpClient = inject(HttpClient);
  private _mockFraud: MockFraudService = inject(MockFraudService);

  get isMockData(): boolean {
    return environment.mockData;
  }

  // Operations
  getOperations(): Observable<PaginationResponse<Operation>> {
    return this._httpClient.get<PaginationResponse<Operation>>(`${ this._apiUrl }/api/operations`);
  }

  // Public
  handleProcessingCallback(body: TransactionDto): Observable<boolean> {
    return this._httpClient.post<boolean>(`${ this._apiUrl }/api/public/processing/callback`, body);
  }

  // Referrals
  getReferrals(): Observable<Referral[]> {
    return this._httpClient.get<Referral[]>(`${ this._apiUrl }/api/referrals`);
  }

  createReferral(body: CreateReferralLinkRequest): Observable<Referral> {
    return this._httpClient.post<Referral>(`${ this._apiUrl }/api/referrals`, body);
  }

  // Rental
  deleteRental(uuid: string): Observable<void> {
    return this._httpClient.delete<void>(`${ this._apiUrl }/api/rental/${uuid}`);
  }

  cancelInvoice(id: string): Observable<any> {
    return this._httpClient.delete<any>(`${ this._apiUrl }/api/invoices/cancel/${ id }`);
  }

  getPendingRental(uuid: string): Observable<Rental | undefined> {
    return this._httpClient.get<Rental | undefined>(`${ this._apiUrl }/api/rental/pending/wallet/${uuid}`)
  }

  validWallet(address: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('address', address.toString());
    return this._httpClient.get<boolean>(`${ this._apiUrl }/api/rental/valid/${address}`);
  }

  calculateRentalPlanForWallet(address: string): Observable<PlanSuggestion> {
    if (this.isMockData) {
      return this._mockFraud.calculateRentalPlanForWallet(address);
    }
    return this._httpClient.get<PlanSuggestion>(`${ this._apiUrl }/api/rental/${ address }`);
  }

  calculatePlanCostForWallet(params: { incoming: number, outcoming: number, days?: number }): Observable<PlanCost> {
    return this._httpClient.get<PlanCost>(`${ this._apiUrl }/api/rental/plan-cost`, { params });
  }

  rentResourcesForWallet(body: RentResourcesRequest): Observable<{id: string}> {
    return this._httpClient.post<{id: string}>(`${ this._apiUrl }/api/rental`, body);
  }

  startTrial(address: string): Observable<void> {
    return this._httpClient.post<void>(`${ this._apiUrl }/api/rental/startTrial/${ address }`, {});
  }

  activeRentalPlan(uuid: string): Observable<void> {
    return this._httpClient.post<void>(`${ this._apiUrl }/api/rental/${uuid}/activate`, {});
  }

  // Transaction operations
  getTransactionByUuid(uuid: string): Observable<TransactionEntity<LatestAmlReport>> {
    return this._httpClient.get<TransactionEntity>(`${ this._apiUrl }/api/aml-scans/${ uuid }`).pipe(
      filter(Boolean),
      map((transaction) => {
        const latestAmlReport = JSON.parse(transaction.latestAmlReport) as LatestAmlReport;
        return {
          ...transaction,
          latestAmlReport
        }
      }),
    )
  }

  // Users
  getUserMe(): Observable<UserEntity> {
    return this._httpClient.get<UserEntity>(`${ this._apiUrl }/api/v1/users/me`);
  }

  getDepositAddress(): Observable<string> {
    return this._httpClient.get(`${ this._apiUrl }/api/v1/users/me/deposit-address`, { responseType: 'text' });
  }

  register(body: RegisterUserForm): Observable<UserEntity> {
    return this._httpClient.post<UserEntity>(`${ this._apiUrl }/api/v1/users/register`, body);
  }

  getUserByTelegramChatId(id: string): Observable<UserEntity> {
    return this._httpClient.get<UserEntity>(`${ this._apiUrl }/api/v1/users/telegram/me/${ id }`);
  }

  // Wallet
  getSubscribedWallets(): Observable<WalletWithRentalDto[]> {
    return this._httpClient.get<WalletWithRentalDto[]>(`${ this._apiUrl }/api/v1/wallet`);
  }

  addWallet(body: AddWalletRequest): Observable<WalletEntity> {
    return this._httpClient.post<WalletEntity>(`${ this._apiUrl }/api/v1/wallet`, body);
  }

  getWallet(uuid: string): Observable<Wallet> {
    return this._httpClient.get<Wallet>(`${ this._apiUrl }/api/v1/wallet/${ uuid }`);
  }

  patchUpdateWallet(uuid: string, body: Partial<Pick<Wallet, 'address' | 'name'>>): Observable<Wallet> {
    return this._httpClient.patch<Wallet>(`${ this._apiUrl }/api/v1/wallet/${ uuid }`, body);
  }

  getInfoWallet(address: string): Observable<Wallet> {
    return this._httpClient.get<Wallet>(`${ this._apiUrl }/api/v1/wallet/address/${ address }`);
  }

  deleteWallet(uuid: string): Observable<void> {
    return this._httpClient.delete<void>(`${ this._apiUrl }/api/v1/wallet/${ uuid }`);
  }

  getLatestReportById(id: number): Observable<Report<ReportData> | undefined> {
    if (this.isMockData) {
      return this._mockFraud.getLatestReportById(id).pipe(map(report => ({
        ...report,
        // data: JSON.parse(report.data)
      })));
    }
    return this._httpClient.get<Report>(`${ this._apiUrl }/api/v1/reports/${ id }`).pipe(
      filter(Boolean),
      map(report => ({
        ...report,
        data: JSON.parse(report.data),
      })), catchError(() => of(undefined)));
  }

  getRentalWallet(uuid: string): Observable<Rental[]> {
    return this._httpClient.get<Rental[]>(`${ this._apiUrl }/api/rental/wallet/${ uuid }`);
  }

  getInvoice(id: string): Observable<InvoiceDto> {
    return this._httpClient.get<InvoiceDto>(`${ this._apiUrl }/api/invoices/${ id }`);
  }

  // Wallet Check
  getCheckWalletsNetworks(): Observable<WalletCheckNetworkDto[]> {
    return this._httpClient.get<WalletCheckNetworkDto[]>(`${ this._apiUrl }/api/v1/wallet-check/networks`);
  }

  getCheckWalletsPending(): Observable<WalletCheckQueueDto> {
    return this._httpClient.get<WalletCheckQueueDto>(`${ this._apiUrl }/api/v1/wallet-check/pending`);
  }

  getCheckWalletsById(id: number): Observable<WalletCheckQueueDto> {
    return this._httpClient.get<WalletCheckQueueDto>(`${ this._apiUrl }/api/v1/wallet-check/${id}`);
  }

  postCheckWallet(form: {address: string, network: string}): Observable<WalletCheckQueueDto> {
    // TODO For version 2.0
    const body = {
      ...form,
      // reportProcess: 'MONITORING_WALLET_CHECK'
    };
    return this._httpClient.post<WalletCheckQueueDto>(`${ this._apiUrl }/api/v1/wallet-check`, body)
  }

  postCheckWalletValidate(body: {address: string, network: string}): Observable<void> {
    return this._httpClient.post<void>(`${ this._apiUrl }/api/v1/wallet-check/validate`, body);
  }

  deleteCheckWalletPending(): Observable<void> {
    return this._httpClient.delete<void>(`${ this._apiUrl }/api/v1/wallet-check/pending`);
  }
}
